import * as React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  Toolbar,
  SaveButton,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import CustomDateInput from '../../components/CustomDateInput'

const ConditionInterventionDateInput = ({ record }) => record
  && (record.status === 'DATE_ACCEPTED' || record.status === 'ONGOING_WORK')
  ? <CustomDateInput source="interventionDate" label="Date d'intervention" />
  : null

const InquiryEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const InquiryEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<InquiryEditToolbar />}>
      <TextInput source="id" label="ID Demande" disabled />
      <TextInput source="analyticLink" label="Code analytique" />
      {props.permissions === 'ADMIN'
        && (
        <ReferenceInput
          label="Chargé d'affaires"
          source="professionalId"
          reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
          target="id"
          link="show"
        >
          <SelectInput optionText={(record) => `${record.firstName} ${record.lastName}`} />
        </ReferenceInput>
        )}
      <ConditionInterventionDateInput />
    </SimpleForm>
  </Edit>
)

export default InquiryEdit
