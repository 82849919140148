import * as React from 'react'
import {
  Create, maxLength, minLength, required, SimpleForm, TextInput, BooleanInput, email,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const validateTrigram = [required(), minLength(3), maxLength(3)]

const validatePassword = [required(), minLength(6), maxLength(20)]

const validateRequired = [required()]

const validateEmail = [required(), email()]

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.PROFESSIONALS}/${data.id}/show`

const ProfessionalCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <TextInput source="firstName" label="Prénom" validate={validateRequired} />
      <TextInput source="lastName" label="Nom" validate={validateRequired} />
      <TextInput source="email" label="Email" validate={validateEmail} />
      <TextInput source="password" type="password" label="Mot de passe" validate={validatePassword} />
      <TextInput source="trigram" label="Trigramme" validate={validateTrigram} />
      <TextInput source="legalEntity" label="Entité Légale" validate={validateRequired} multiline />
      <BooleanInput label="Donner les droits d'administration" source="isAdmin" />
    </SimpleForm>
  </Create>
)

export default ProfessionalCreate
