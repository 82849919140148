import * as React from 'react'
import { Admin, Resource, Login } from 'react-admin'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import frenchMessages from 'ra-language-french'
import Person from '@material-ui/icons/Person'
import dotenv from 'dotenv'
import { createMuiTheme } from '@material-ui/core/styles'
import red from '@material-ui/core/colors/red'
import { indigo } from '@material-ui/core/colors'
import HttpProvider from './providers/http/provider'
import authProvider from './providers/http/authProvider'
import ProfessionalList from './pages/professionals/ProfessionalList'
import ProfessionalCreate from './pages/professionals/ProfessionalCreate'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from './config/resources'
import CustomerList from './pages/customers/CustomerList'
import CustomerCreate from './pages/customers/CustomerCreate'
import ProfessionalEdit from './pages/professionals/ProfessionalEdit'
import ProfessionalShow from './pages/professionals/ProfessionalShow'
import CustomerEdit from './pages/customers/CustomerEdit'
import CustomerShow from './pages/customers/CustomerShow'
import InquiryList from './pages/inquiries/InquiryList'
import QuoteCreate from './pages/quotes/QuoteCreate'
import addUploadCapabilities from './providers/http/addUploadCapabilities'
import InquiryShow from './pages/inquiries/InquiryShow'
import BDCCreate from './pages/bdcs/BDCCreate'
import DateCreate from './pages/dates/DateCreate'
import BICreate from './pages/bi/BICreate'
import PVCreate from './pages/pv/PVCreate'
import MiscCreate from './pages/miscs/MiscCreate'
import InquiryEdit from './pages/inquiries/InquiryEdit'
import BillCreate from './pages/bills/BillCreate'
import BillInquiryCreate from "./pages/bills/BillInquiryCreate";


dotenv.config()

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr')

const CustomTheme = createMuiTheme({
  palette: {
    primary: indigo,
    secondary: {
      main: '#3298B2',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
})

const CustomLoginPage = () => (
  <Login
    backgroundImage={require('./assets/images/background_bleu_fauche.png')}
  />
)

const App = () => (
  <Admin
    locale="fr"
    i18nProvider={i18nProvider}
    dataProvider={addUploadCapabilities(HttpProvider)}
    authProvider={authProvider}
    theme={CustomTheme}
    loginPage={CustomLoginPage}
  >
    {(permissions) => [
      permissions === 'ADMIN' ? (
        <Resource
          name={RESOURCE_IDENTIFIERS.PROFESSIONALS}
          list={ProfessionalList}
          create={ProfessionalCreate}
          edit={ProfessionalEdit}
          show={ProfessionalShow}
          icon={Person}
          options={{ label: 'Chargés d\'affaires' }}
        />
      ) : null,
      <Resource
        name={RESOURCE_IDENTIFIERS.CUSTOMERS}
        list={CustomerList}
        create={CustomerCreate}
        edit={CustomerEdit}
        show={CustomerShow}
        icon={Person}
        options={{ label: 'Clients' }}
      />,
      <Resource
        name={RESOURCE_IDENTIFIERS.INQUIRIES}
        list={InquiryList}
        show={InquiryShow}
        edit={InquiryEdit}
        options={{ label: 'Affaires' }}
      />,
      <Resource name={RESOURCE_IDENTIFIERS.QUOTES} create={QuoteCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.BDCS} create={BDCCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.MISCS} create={MiscCreate} />,

      <Resource name={RESOURCE_IDENTIFIERS.DATES} create={DateCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.BI} create={BICreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.PV} create={PVCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.BILLS} create={BillCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.BILL_INQUIRY} create={BillInquiryCreate} />,
      <Resource name={RESOURCE_IDENTIFIERS.DOCUMENTS} />,
    ]}
  </Admin>
)

export default App
