import * as React from 'react'
import {
  Create, SimpleForm, TextInput, DateInput, NumberInput,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import CustomDateInput from '../../components/CustomDateInput'
import CustomDateTimeInput from '../../components/CustomDateTimeInput'

function HourlyRateField({ record }) {
  if (record?.type === 'URGENT') {
    return <NumberInput source="hourlyRate" label="Taux horaire" />
  }
  return null
}

function TravelExpensesField({ record }) {
  if (record?.type === 'URGENT') {
    return <NumberInput source="travelExpenses" label="Frais de déplacement" />
  }
  return null
}

function InterventionDateField({ record }) {
  return record?.type === 'URGENT'
    ? <CustomDateTimeInput source="interventionDate" label="Date d'intervention" />
    : <CustomDateInput source="interventionDate" label="Date d'intervention" />
}

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.INQUIRIES}/${data.inquiryId}/show`

const Title = () => <span>Proposer une date d'intervention</span>

const DateCreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <TextInput source="inquiryId" label="ID Demande" disabled />
      <InterventionDateField />
      <HourlyRateField />
      <TravelExpensesField />
    </SimpleForm>
  </Create>
)

export default DateCreate
