import React from 'react'
import {List, Datagrid, TextField, SelectField, ReferenceField, FunctionField} from 'react-admin'
import {IDENTIFIERS as RESOURCE_IDENTIFIERS} from "../../config/resources";

const CustomerList = (props) => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
        {props.permissions === 'ADMIN'
        && (
            <ReferenceField
                label="Chargé d'affaires attribué"
                source="ownerProfessionalId"
                reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
                target="id"
                link="show"
                allowEmpty
            >
                <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
            </ReferenceField>
        )}
      <TextField source="company" label="Entreprise" />
      <TextField source="email" label="Email" />
      <TextField source="trigram" label="Trigramme" />
        <SelectField source="status" choices={[
            {id: 'ACTIVE', name: 'Actif'}, {id: 'SUSPENDED', name:'Suspendu'}
        ]} label="Statut" />
    </Datagrid>
  </List>
)

export default CustomerList
