import * as React from 'react'

// eslint-disable-next-line complexity
function InquiryStatus({ record }) {
  switch (record.status) {
    case 'NEW': return (<span>Nouveau</span>)
    case 'WAITING_QUOTE': return <span>En attente de devis</span>
    case 'QUOTE_PROPOSAL': return <span>Devis envoyé</span>
    case 'QUOTE_ACCEPTED': return <span>Devis accepté</span>
    case 'WAITING_PROPOSAL': return <span>En attente d'une proposition</span>
    case 'DATE_PROPOSAL': return <span>Proposition de date envoyée</span>
    case 'DATE_ACCEPTED': return <span>Date acceptée</span>
    case 'ONGOING_WORK': return <span>Intervention en cours</span>
    case 'WORK_DONE': return <span>Intervention terminée</span>
    case 'PV_SENT': return <span>Procès verbal envoyé</span>
    case 'PV_ACCEPTED': return <span>Procès verbal accepté</span>
    case 'BI_SENT': return <span>Bon d'intervention envoyé</span>
    case 'BILL_SENT': return <span>Facture envoyée</span>
    case 'FINISHED': return <span>Terminée</span>
    default: return null
  }
}

InquiryStatus.defaultProps = {
  addLabel: true,
}

export default InquiryStatus
