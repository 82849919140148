import * as React from 'react'
import {
  Edit, SimpleForm, TextInput, SelectInput, ReferenceInput, Toolbar, SaveButton, required, minLength, maxLength, email,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.CUSTOMERS}/${data.id}/show`

const CustomerEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const validatePassword = [minLength(6), maxLength(20)]

const validateRequired = [required()]

const validateEmail = [required(), email()]

const CustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect={redirect} toolbar={<CustomerEditToolbar />}>
      <TextInput source="firstName" label="Prénom" validate={validateRequired} />
      <TextInput source="lastName" label="Nom" validate={validateRequired} />
      <TextInput source="company" label="Entreprise" validate={validateRequired} />
      {props.permissions === 'ADMIN'
      && (
      <ReferenceInput
        label="Chargé d'affaires"
        source="ownerProfessionalId"
        reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
        target="id"
        link="show"
      >
        <SelectInput optionText={(record) => `${record.firstName} ${record.lastName}`} />
      </ReferenceInput>
      )}
      <TextInput source="email" label="Email" type="email" validate={validateEmail} />
      <TextInput source="password" type="password" label="Mot de passe" validate={validatePassword} />
      <SelectInput
        source="status"
        choices={[
          { id: 'ACTIVE', name: 'Actif' }, { id: 'SUSPENDED', name: 'Suspendu' },
        ]}
        label="Statut"
      />
    </SimpleForm>
  </Edit>
)

export default CustomerEdit
