import React from 'react'
import { useCreate, useRedirect } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../../config/resources'

const useStyles = makeStyles({
  button: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1em',
    cursor: 'pointer',
    borderRadius: '15px',
    backgroundColor: '#2096f2',
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0.3em',
    margin: '0.5em 0em 0.5em',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1b79c2',
    },
    '&:active': {
      boxShadow: 'none',
      top: '5px',
    },
  },
})

// eslint-disable-next-line no-unused-vars,complexity
const StatusActionField = ({ record }) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const [AcknowledgeQuote] = useCreate(RESOURCE_IDENTIFIERS.INQUIRIES_ACK, { inquiryId: record.id }, {
    onSuccess: () => {
      redirect('/')
      redirect(`/${RESOURCE_IDENTIFIERS.INQUIRIES}`)
    },
  })
  if (record) {
    switch (record.status) {
      case 'NEW': return (
        <a
          type="button"
          role="button"
          href={`/#/${RESOURCE_IDENTIFIERS.INQUIRIES}`}
          onClick={AcknowledgeQuote}
          className={classes.button}
        >Prendre en charge la demande
        </a>
      )
      case 'WAITING_QUOTE': return (
        <a
          href={`/#/${RESOURCE_IDENTIFIERS.QUOTES}/create?source=${JSON.stringify({ inquiryId: record.id })}`}
          className={classes.button}
        >
          Envoyer un devis
        </a>
      )
      case 'QUOTE_PROPOSAL': return null /* No action at this step */
      case 'WAITING_PROPOSAL':
      case 'QUOTE_ACCEPTED': return (
        <a
          href={`/#/${RESOURCE_IDENTIFIERS.DATES}/create?source=${JSON.stringify({ inquiryId: record.id, type: record.type })}`}
          className={classes.button}
        >
          {record.interventionDate ? 'Proposer une date définitive' : 'Proposer une date'}
        </a>
      )
      case 'DATE_PROPOSAL': return null /* No action at this step */
      case 'DATE_ACCEPTED': return null /* No action at this step */
      case 'ONGOING_WORK': return (
        <div>
          <a
            href={`/#/${RESOURCE_IDENTIFIERS.BI}/create?source=${JSON.stringify({ inquiryId: record.id, type: record.type })}`}
            className={classes.button}
          >
            Envoyer un bon d'intervention
          </a>
          {
            record.type === 'STANDARD' ? (
              <a
                href={`/#/${RESOURCE_IDENTIFIERS.PV}/create?source=${JSON.stringify({ inquiryId: record.id, analyticLink: record.analyticLink })}`}
                className={classes.button}
              >
                Envoyer un procès verbal
              </a>
            ) : null
          }
        </div>
      )
      case 'WORK_DONE': return null /* Not implemented */
      case 'PV_SENT': return null /* Not implemented */
      case 'BILL_DECLINED':
      case 'PV_ACCEPTED':
      case 'BI_SENT':
        return (
          <div>
            <a
              href={`/#/${RESOURCE_IDENTIFIERS.BILL_INQUIRY}/create?source=${JSON.stringify({ inquiryId: record.id, analyticLink: record.analyticLink })}`}
              className={classes.button}
            >
              Demander une mise en facturation
            </a>
            <a
              href={`/#/${RESOURCE_IDENTIFIERS.BILLS}/create?source=${JSON.stringify({ inquiryId: record.id, inquiryStatus: record.status })}`}
              className={classes.button}
            >
              Envoyer une facture
            </a>
          </div>
        )
      case 'BILL_SENT': return null /* Not implemented */
      case 'FINISHED': return null /* Not implemented */
      default: return null
    }
  }
  return null
}

StatusActionField.defaultProps = {
  addLabel: true,
}

export default StatusActionField
