import * as React from 'react'
import {
  Create, email, required, SimpleForm, TextInput,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.INQUIRIES}/${data.inquiryId}/show/1`

const Title = () => <span>Demander une mise en facturation</span>

const validateRequired = [required()]

const validateEmail = [required(), email()]

const BillInquiryCreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <TextInput source="inquiryId" label="ID Demande" disabled />
      <TextInput source="analyticLink" label="Code analytique" validate={validateRequired} />
      <TextInput source="email" label="Email" validate={validateEmail} />
    </SimpleForm>
  </Create>
)

export default BillInquiryCreate
