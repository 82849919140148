import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../../config/resources'

const useStyles = makeStyles({
  button: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1em',
    cursor: 'pointer',
    borderRadius: '15px',
    backgroundColor: '#2096f2',
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0.3em',
    width: '100%',
    '&:hover': {
      backgroundColor: '#1b79c2',
    },
    '&:active': {
      boxShadow: 'none',
      top: '5px',
    },
  },
})

// eslint-disable-next-line no-unused-vars,complexity
const AddMiscButton = ({ record }) => {
  const classes = useStyles()
  return record.docs.filter((doc) => doc.type === 'OTHER').length < 5 ? (
    <a
      href={`/#/${RESOURCE_IDENTIFIERS.MISCS}/create?source=${JSON.stringify({ inquiryId: record.id })}`}
      className={classes.button}
    >
      Ajouter un document dans "Divers"
    </a>
  ) : <span>La limite de document dans le dossier "Divers" est atteinte (5 documents maximum)</span>
}

AddMiscButton.defaultProps = {
  addLabel: true,
}

export default AddMiscButton
