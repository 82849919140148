import * as React from 'react'

// eslint-disable-next-line complexity
function InquiryType({ record }) {
  switch (record.type) {
    case 'STANDARD': return <span>Travaux</span>
    case 'URGENT': return <span>Dépannage</span>
    default: return null
  }
}

InquiryType.defaultProps = {
  addLabel: true,
}

export default InquiryType
