import axios from 'axios'
import { HttpError} from 'react-admin'

const httpClient = (params = {}) => {
  const options = params
  if (!params.headers) {
    options.headers = { Accept: 'application/json' }
  }
  options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return axios(options).catch((err) => {
    return Promise.reject(new HttpError(
        err.response?.data?.message,
        err.response?.status,
        err.response?.data
    ))
    //localStorage.setItem('needsRefresh', err.message.includes('401'))
    //throw Error('Refreshing...')
  })
}

export default httpClient
