import {
  AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS,
} from 'react-admin'
import axios from 'axios'
import config from '../../config/config'

export default (type, params) => {
  // Called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    return axios
      .post(`${config.apiUrl}/auth/login/email`, {
        email: username,
        password,
      })
      .then((response) => {
        localStorage.setItem('accessToken', response.data.accessToken)
        localStorage.setItem('refreshToken', response.data.refreshToken)
        localStorage.setItem('authority', response.data.authority)
      }).catch(() => {
        throw new Error('Identifiants invalides')
      })
  }
  // Called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('authority')
    return Promise.resolve()
  }
  // Called when the API returns an error
  if (type === AUTH_ERROR) {
    return axios
      .post(`${config.apiUrl}/auth/refresh`, {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      })
      .then((response) => {
        localStorage.setItem('accessToken', response.data.accessToken)
        localStorage.setItem('refreshToken', response.data.refreshToken)
        localStorage.setItem('authority', response.data.authority)
        if (localStorage.getItem('needsRefresh') === true) {
          return window.location.reload()
        }
      })
      .catch(() => {
        // eslint-disable-next-line prefer-promise-reject-errors
        Promise.reject({ redirectTo: '/login' })
      })
  }
  // Called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const authority = localStorage.getItem('authority')
    return authority ? Promise.resolve(authority) : Promise.reject()
  }

  return Promise.reject(Error('Unknown Method'))
}
