import React from 'react'
import {
  List, Datagrid, TextField, DateField, ReferenceField, FunctionField, CheckboxGroupInput, Filter, SelectInput,
} from 'react-admin'
import StatusActionField from './components/StatusActionField'
import InquiryStatus from './components/InquiryStatus'
import InquiryType from './components/InquiryType'
import BulkActionsButtons from '../../components/BulkActionButtons'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import InterventionDateField from './components/InterventionDateField'

const inquiryListRowStyle = (record, index) => ({
  backgroundColor: record.type === 'URGENT' ? '#fdf9d1' : 'white',
})

const InquiryFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="type"
      label="Type :"
      alwaysOn
      choices={[
        { id: 'STANDARD', name: 'Travaux' },
        { id: 'URGENT', name: 'Dépannages' },
      ]}
      allowEmpty
      resettable
    />
    <SelectInput
      source="status"
      label="Statut :"
      alwaysOn
      choices={[
        { id: 'OPENED', name: 'En cours' },
        { id: 'FINISHED', name: 'Terminées' },
      ]}
      allowEmpty
      resettable
    />
  </Filter>
)

const Expand = ({ id, record, resource }) => (
  <div>
    <p><b>Description : </b>{record.description}</p>
    <p><b>Adresse : </b>{record.address.street} {record.address.postalCode} {record.address.city}</p>
    {record.hourlyRate ? (<p><b>Taux horaire : </b>{record.hourlyRate} €</p>) : null}
    {record.travelExpenses ? (<p><b>Frais de déplacement : </b>{record.travelExpenses} €</p>) : null}
  </div>
)

const InquiryList = (props) => (
  <List
    {...props}
    filters={<InquiryFilter />}
    bulkActionButtons={<BulkActionsButtons />}
    exporter={false}
    sort={{ field: 'createdDate', order: 'DESC' }}
  >
    <Datagrid rowClick="show" expand={<Expand />} rowStyle={inquiryListRowStyle}>
      <ReferenceField
        label="Client"
        source="customerId"
        reference={RESOURCE_IDENTIFIERS.CUSTOMERS}
        target="id"
        link="show"
        allowEmpty
      >
        <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
      </ReferenceField>
      {props.permissions === 'ADMIN'
            && (
            <ReferenceField
              label="Chargé d'affaires"
              source="professionalId"
              reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
              target="id"
              link="show"
              allowEmpty
            >
              <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
            </ReferenceField>
            )}
      <FunctionField
        label="identifiant"
        render={(record) => `${record.identifier}${record.analyticLink ? (`-${record.analyticLink}`) : ''}`}
      />
      <InquiryType label="Type" />
      <TextField source="title" label="Titre" />
      <InquiryStatus label="Statut" />
      <InterventionDateField label="Début des travaux" source="interventionDate" />
      <InterventionDateField label="Fin des travaux" source="endInterventionDate" />
      <DateField source="createdDate" label="Date de création" />
      <StatusActionField label="Action" />
    </Datagrid>
  </List>
)

export default InquiryList
