import * as React from 'react'
import {
  Create, SimpleForm, TextInput, FileInput, FileField, required,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import CustomDateInput from '../../components/CustomDateInput'
import CustomDateTimeInput from '../../components/CustomDateTimeInput'

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.INQUIRIES}/${data.inquiryId}/show/1`

const Title = () => <span>Ajouter un procès-verbal</span>

const validateRequired = [required()]

function InterventionDateField({ record }) {
  return record?.type === 'URGENT'
    ? <CustomDateTimeInput source="endInterventionDate" label="Date de fin d'intervention" />
    : <CustomDateInput source="endInterventionDate" label="Date de fin d'intervention" />
}

const PVCreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <TextInput source="inquiryId" label="ID Demande" disabled style={{ width: 300 }} />
      <TextInput source="analyticLink" label="Code analytique" validate={validateRequired} />
      <InterventionDateField />
      <TextInput source="quoteNumber" label="N°de devis" style={{ width: 300 }} validate={validateRequired} />
      <TextInput source="bdcNumber" label="N°de bon de commande (optionnel)" style={{ width: 300 }} />
      <FileInput source="additionalFiles" label="Photos" accept=".jpg,.png,.jpeg" maxSize="5000000" multiple>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
    </SimpleForm>
  </Create>
)

export default PVCreate
