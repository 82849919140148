import * as React from 'react'

// eslint-disable-next-line complexity
function EventType({ record }) {
  switch (record.type) {
    case 'INQUIRY_CREATION': return (<span>Création de la demande</span>)
    case 'INQUIRY_ACK': return (<span>Prise en compte de la demande</span>)
    case 'QUOTE_SENDING': return (<span>Envoi du devis</span>)
    case 'QUOTE_ACCEPT': return (<span>Validation du devis</span>)
    case 'QUOTE_DECLINE': return (<span>Refus du devis</span>)
    case 'DATE_PROPOSAL': return (<span>Proposition de date</span>)
    case 'DATE_ACCEPT': return (<span>Validation de la date</span>)
    case 'DATE_DECLINE': return (<span>Refus de la date</span>)
    case 'WORK_START': return (<span>Début de l'intervention</span>)
    case 'WORK_END': return (<span>Fin de l'intervention</span>)
    case 'PV_SENDING': return (<span>Envoi du procès-verbal</span>)
    case 'PV_ACCEPT': return (<span>Validation du procès-verbal</span>)
    case 'BI_SENDING': return (<span>Envoi du bon d'intervention</span>)
    case 'BILL_INQUIRY': return (<span>Mise en facturation</span>)
    case 'BILL_SENDING': return (<span>Envoi de la facture</span>)
    case 'BILL_ACK': return (<span>Prise en charge de la facture</span>)
    case 'BILL_DECLINED': return (<span>Refus de la facture</span>)
    case 'BDC_SENDING': return (<span>Ajout du bon de commande</span>)
    case 'BDC_DELETE': return (<span>Suppression du bon de commande</span>)
    case 'INQUIRY_FINISHED': return (<span>Affaire terminée</span>)
    default: return null
  }
}

EventType.defaultProps = {
  addLabel: true,
}

export default EventType
