import * as React from 'react'
import {
  Create, SimpleForm, TextInput, FileInput, FileField,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import CustomDateTimeInput from '../../components/CustomDateTimeInput'
import CustomDateInput from "../../components/CustomDateInput";

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.INQUIRIES}/${data.inquiryId}/show/1`

const Title = () => <span>Ajouter un bon d'intervention</span>

function InterventionDateField({ record }) {
  console.log(record)
  return record?.type === 'URGENT'
    ? <CustomDateTimeInput source="endInterventionDate" label="Date de fin d'intervention" />
    : <CustomDateInput source="endInterventionDate" label="Date de fin d'intervention" />
}

const BICreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <TextInput source="inquiryId" label="ID Demande" disabled />
      <InterventionDateField />
      <FileInput source="uploaded_file" label="Bon d'intervention" accept=".pdf" maxSize="5000000">
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
      <FileInput source="additionalFiles" label="Photos" accept=".jpg,.png,.jpeg" maxSize="5000000" multiple>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
    </SimpleForm>
  </Create>
)

export default BICreate
