import * as React from 'react'
import {
  Create, SimpleForm, TextInput, FileInput, FileField,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.INQUIRIES}/${data.inquiryId}/show/1`

const Title = () => <span>Ajouter un devis</span>

const QuoteCreate = (props) => (
  <Create {...props} title={<Title />}>
    <SimpleForm redirect={redirect}>
      <TextInput source="inquiryId" label="ID Demande" disabled />
      <FileInput source="uploaded_file" label="Devis" accept=".pdf" maxSize="5000000">
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
    </SimpleForm>
  </Create>
)

export default QuoteCreate
