import * as React from 'react'
import httpClient from '../../../providers/http/httpClient'
import config from '../../../config/config'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../../config/resources'
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
  button: {
    display: 'block',
    textAlign: 'center',
    fontSize: '1em',
    cursor: 'pointer',
    borderRadius: '15px',
    backgroundColor: '#2096f2',
    color: 'white',
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: '0.3em',
    '&:hover': {
      backgroundColor: '#1b79c2',
    },
    '&:active': {
      boxShadow: 'none',
      top: '5px',
    },
  },
})

export default function AuthenticatedLink({ record }) {
  const classes = useStyles()
  const link = React.createRef()

  const handleAction = async () => {
    if (link.current.href) { return }

    const result = await httpClient({
      url: `${config.apiUrl}/${RESOURCE_IDENTIFIERS.DOCUMENTS}/${record.id}`,
      method: 'GET',
      responseType: 'blob',
    })

    const blob = result.data
    const href = window.URL.createObjectURL(blob)

    link.current.download = record.name
    link.current.href = href

    link.current.click()
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <a ref={link} onClick={handleAction} className={classes.button}>Télécharger</a>
  )
}
