import * as React from 'react'
import {
  Datagrid, DateField, FunctionField, ReferenceField, Show, ArrayField, TextField, TabbedShowLayout, Tab, NumberField, DeleteWithConfirmButton,
  Labeled,
} from 'react-admin'
import InquiryStatus from './components/InquiryStatus'
import StatusActionField from './components/StatusActionField'
import AuthenticatedLink from './components/AuthenticatedLink'
import FolderType from './components/FolderType'
import AddMiscButton from './components/AddMiscButton'
import AddBDCButton from './components/AddBDCButton'
import EventType from './components/EventType'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import InterventionDateField from './components/InterventionDateField'

const UrgentInquiryHourlyRate = ({ record, ...rest }) => record.hourlyRate ? (
  <Labeled label="Taux horaire">
    <NumberField
      source="hourlyRate"
      options={{ style: 'currency', currency: 'EUR' }}
      record={record}
      {...rest}
    />
  </Labeled>
) : null


const UrgentInquiryTravelExpenses = ({ record, ...rest }) => record.travelExpenses ? (
  <Labeled label="Frais de déplacement">
    <NumberField
      source="travelExpenses"
      options={{ style: 'currency', currency: 'EUR' }}
      record={record}
      {...rest}
    />
  </Labeled>
) : null

const Title = ({ record }) => <span>Affaire #{record.identifier}{record.analyticLink ? `-${record.analyticLink}` : ''}</span>

const DeleteDocumentButton = ({ record, ...rest }) => record.type === 'BDC' || record.type === 'OTHER'
  ? (
    <DeleteWithConfirmButton
      record={record}
      {...rest}
      confirmTitle={`Supprimer ${record.name}`}
      resource={RESOURCE_IDENTIFIERS.DOCUMENTS}
      redirect="1"
    />
  )
  : null

const InquiryShow = (props) => (
  <Show {...props} title={<Title />}>
    <TabbedShowLayout>
      <Tab label="Détails">
        <StatusActionField label="Action" />
        <InquiryStatus label="Statut" />
        <ReferenceField
          label="Client"
          source="customerId"
          reference={RESOURCE_IDENTIFIERS.CUSTOMERS}
          target="id"
          link="show"
          allowEmpty
        >
          <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
        </ReferenceField>
        {props.permissions === 'ADMIN'
        && (
          <ReferenceField
            label="Chargé d'affaires"
            source="professionalId"
            reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
            target="id"
            link="show"
            allowEmpty
          >
            <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
          </ReferenceField>
        )}
        <TextField source="title" label="Titre" />
        <TextField source="description" label="Description" />
        <FunctionField
          label="identifiant"
          render={(record) => `${record.identifier}${record.analyticLink ? (`-${record.analyticLink}`) : ''}`}
        />
        <TextField source="analyticLink" label="Code analytique" />
        <UrgentInquiryHourlyRate />
        <UrgentInquiryTravelExpenses />
        <InterventionDateField source="interventionDate" label="Début des travaux" />
        <InterventionDateField source="endInterventionDate" label="Fin des travaux" />
        <DateField source="createdDate" label="Date de création" />
        <TextField source="id" label="ID" />
      </Tab>
      <Tab label="Documents">
        <AddBDCButton label="Action" />
        <AddMiscButton label="Action" />
        <ArrayField source="docs" label="Documents">
          <Datagrid>
            <FolderType label="Dossier" />
            <TextField source="name" label="Nom" />
            <DateField source="createdDate" label="Date de création" />
            <AuthenticatedLink />
            <DeleteDocumentButton />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Historique">
        <ArrayField source="history" label="Historique">
          <Datagrid>
            <DateField source="createdDate" label="Date" />
            <EventType source="type" label="Evénement" />
            <TextField source="comment" label="Commentaire" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default InquiryShow
