import * as React from 'react'
import {
  Create, SimpleForm, TextInput, required, minLength, maxLength, email
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const validateTrigram = [required(), minLength(3), maxLength(3)]

const validatePassword = [required(), minLength(6), maxLength(20)]

const validateRequired = [required()]

const validateEmail = [required(), email()]

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.CUSTOMERS}/${data.id}/show`

const CustomerCreate = (props) => (
  <Create {...props} redirect={redirect}>
    <SimpleForm>
      <TextInput source="firstName" label="Prénom" validate={validateRequired} />
      <TextInput source="lastName" label="Nom" validate={validateRequired} />
      <TextInput source="company" label="Entreprise" validate={validateRequired} />
      <TextInput source="trigram" label="Trigramme" validate={validateTrigram} />
      <TextInput source="email" label="Email" type="email" validate={validateEmail} />
      <TextInput source="password" type="password" label="Mot de passe" validate={validatePassword} />
    </SimpleForm>
  </Create>
)

export default CustomerCreate
