import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.rawFile)

  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

const addUploadCapabilities = (requestHandler) => async (type, resource, params) => {
  let newParams = params
  if ((type === 'UPDATE' || type === 'CREATE')
        && ((resource === RESOURCE_IDENTIFIERS.QUOTES
            || (resource === RESOURCE_IDENTIFIERS.BDCS)
            || (resource === RESOURCE_IDENTIFIERS.BI)
            || (resource === RESOURCE_IDENTIFIERS.PV)
            || (resource === RESOURCE_IDENTIFIERS.BILLS)
            || (resource === RESOURCE_IDENTIFIERS.MISCS)))) {
    if (params.data && params.data.uploaded_file) {
      const myFile = params.data.uploaded_file
      if (myFile.rawFile instanceof File) {
        const fileBase64 = await convertFileToBase64(myFile)
        newParams = {
          ...params,
          data: {
            ...params.data,
            upload_encoded: fileBase64,
          },
        }
      }
    }

    if (resource === RESOURCE_IDENTIFIERS.BILLS && params.data && params.data.credit_file) {
      const myFile = params.data.credit_file
      if (myFile.rawFile instanceof File) {
        const fileBase64 = await convertFileToBase64(myFile)
        newParams = {
          ...newParams,
          data: {
            ...newParams.data,
            credit_upload_encoded: fileBase64,
          },
        }
      }
    }

    let newBlocks = params.data.additionalFiles
    if (params.data && params.data.additionalFiles) {
      newBlocks = await Promise.all(
        params.data.additionalFiles.map(async (block) => {
          const myFile = block
          if (myFile.rawFile instanceof File) {
            const picture64 = await convertFileToBase64(myFile)
            return { ...block, upload_encoded: picture64 }
          }
          return block
        })
      )
    }
    return requestHandler(type, resource, {
      ...newParams,
      data: {
        ...newParams.data,
        additionalFiles: newBlocks,
      },
    })
  }
  return requestHandler(type, resource, newParams)
}

export default addUploadCapabilities
