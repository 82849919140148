import { DateField } from 'react-admin'
import React from 'react'

const InterventionDateField = ({ record, source }) => {
  if (record && record[source]) {
    return (<DateField source={source} record={record} label="Début des travaux" showTime={record.type === 'URGENT'} />)
  }
  return null
}

InterventionDateField.defaultProps = {
  addLabel: true,
}

export default InterventionDateField
