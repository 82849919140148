import * as React from 'react'

// eslint-disable-next-line complexity
function FolderType({ record }) {
  switch (record.parentFolderType) {
    case 'REQUEST': return (<span>Demande</span>)
    case 'QUOTE': return (<span>Devis</span>)
    case 'BDC': return (<span>BDC</span>)
    case 'RECEPTION': return (<span>Réception</span>)
    case 'BILL': return (<span>Factures</span>)
    case 'MISC': return (<span>Divers</span>)
    default: return null
  }
}

FolderType.defaultProps = {
  addLabel: true,
}

export default FolderType
