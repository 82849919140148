import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import React from 'react'
import { useInput, Labeled } from 'react-admin'
import frLocale from 'date-fns/locale/fr'

const CustomDateTimeInput = (props) => {
  const {
    input: { onChange, value },
  } = useInput(props)
  return (
    <Labeled label={props.label}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
        <DateTimePicker value={value || null} onChange={onChange} format="dd/MM/yyyy HH:mm" ampm={false} />
      </MuiPickersUtilsProvider>
    </Labeled>
  )
}

export default CustomDateTimeInput
