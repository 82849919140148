export const IDENTIFIERS = {
  PROFESSIONALS: 'chargés d\'affaires',
  CUSTOMERS: 'clients',
  INQUIRIES: 'affaires',
  INQUIRIES_ACK: 'inquiries/acknowledge',
  QUOTES: 'devis',
  BDCS: 'bon de commande',
  BI: 'bon d\'intervention',
  PV: 'procès-verbal',
  DOCUMENTS: 'documents',
  DATES: 'dates',
  MISCS: 'divers',
  BILLS: 'factures',
  BILL_INQUIRY: 'demande de mise en facturation',
}

export const resources = {
  [[IDENTIFIERS.PROFESSIONALS]]: {
    name: 'Chargés d\'affaires',
    url: 'professionals',
  },
  [[IDENTIFIERS.CUSTOMERS]]: {
    name: 'Clients',
    url: 'customers',
  },
  [[IDENTIFIERS.INQUIRIES]]: {
    name: 'Demandes',
    url: 'inquiries',
  },
  [[IDENTIFIERS.INQUIRIES_ACK]]: {
    name: 'Prise en compte demande',
    url: 'inquiries/acknowledge',
  },
  [[IDENTIFIERS.QUOTES]]: {
    name: 'Devis',
    url: 'inquiries/quote',
  },
  [[IDENTIFIERS.BDCS]]: {
    name: 'Bon de commande',
    url: 'inquiries/BDC',
  },
  [[IDENTIFIERS.MISCS]]: {
    name: 'Dossier divers',
    url: 'inquiries/MISC',
  },
  [[IDENTIFIERS.DOCUMENTS]]: {
    name: 'Documents',
    url: 'documents',
  },
  [[IDENTIFIERS.DATES]]: {
    name: 'Date d\'intervention',
    url: 'inquiries/date',
  },
  [[IDENTIFIERS.BI]]: {
    name: 'Bon d\'intervention',
    url: 'inquiries/bi',
  },
  [[IDENTIFIERS.PV]]: {
    name: 'Procès verbal',
    url: 'inquiries/pv',
  },
  [[IDENTIFIERS.BILLS]]: {
    name: 'Factures',
    url: 'inquiries/bill',
  },
  [[IDENTIFIERS.BILL_INQUIRY]]: {
    name: 'Demande de mise en facturation',
    url: 'inquiries/billInquiry',
  },
}

/**
 * Returns the URL according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceUrl = (name) => {
  if (resources[name]) {
    return resources[name].url
  }
  return name
}

/**
 * Returns the label according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceLabel = (name) => {
  if (resources[name]) {
    return resources[name].name
  }
  return name
}

export default {
  IDENTIFIERS,
  resources,
  getResourceLabel,
  getResourceUrl,
}
