import * as React from 'react'
import {
  BooleanField,
  SelectField, Show, SimpleShowLayout, TextField,
} from 'react-admin'

const ProfessionalShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
      <TextField source="email" label="Email" />
      <TextField source="trigram" label="Trigramme" />
      <TextField source="legalEntity" label="Entité légale" />
      <SelectField
        source="status"
        choices={[
          { id: 'ACTIVE', name: 'Actif' }, { id: 'SUSPENDED', name: 'Suspendu' },
        ]}
        label="Statut"
      />
      <BooleanField source="isAdmin" label="Administrateur" />
    </SimpleShowLayout>
  </Show>
)

export default ProfessionalShow
