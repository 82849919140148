import * as React from 'react'
import {FunctionField, ReferenceField, SelectField, Show, SimpleShowLayout, TextField} from 'react-admin'
import {IDENTIFIERS as RESOURCE_IDENTIFIERS} from "../../config/resources";

const CustomerShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
      <TextField source="email" label="Email" />
        {props.permissions === 'ADMIN'
        && (
            <ReferenceField
                label="Chargé d'affaires"
                source="ownerProfessionalId"
                reference={RESOURCE_IDENTIFIERS.PROFESSIONALS}
                target="id"
                link="show"
                allowEmpty
            >
                <FunctionField render={(record) => `${record.firstName} ${record.lastName}`} />
            </ReferenceField>
        )}
      <TextField source="company" label="Entreprise" />
      <TextField source="trigram" label="Trigramme" />
        <SelectField source="status" choices={[
            {id: 'ACTIVE', name: 'Actif'}, {id: 'SUSPENDED', name: 'Suspendu'}
        ]} label="Statut"/>
    </SimpleShowLayout>
  </Show>
)

export default CustomerShow
