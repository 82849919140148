import React from 'react'
import {
  List, Datagrid, TextField, BooleanField, SelectField,
} from 'react-admin'

const ProfessionalList = (props) => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="firstName" label="Prénom" />
      <TextField source="lastName" label="Nom" />
      <TextField source="email" label="Email" />
      <TextField source="trigram" label="Trigramme" />
      <TextField source="legalEntity" label="Entité légale" />
      <SelectField
        source="status"
        choices={[
          { id: 'ACTIVE', name: 'Actif' }, { id: 'SUSPENDED', name: 'Suspendu' },
        ]}
        label="Statut"
      />
      <BooleanField source="isAdmin" label="Administrateur" />
    </Datagrid>
  </List>
)

export default ProfessionalList
