import * as React from 'react'
import {
  BooleanInput,
  Edit, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar,
} from 'react-admin'

const ProfessionalEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const ProfessionalEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<ProfessionalEditToolbar />}>
      <TextInput source="firstName" label="Prénom" />
      <TextInput source="lastName" label="Nom" />
      <TextInput source="email" label="Email" />
      <TextInput source="password" type="password" label="Mot de passe" />
      <TextInput source="legalEntity" label="Entité Légale" />
      <SelectInput
        source="status"
        choices={[
          { id: 'ACTIVE', name: 'Actif' }, { id: 'SUSPENDED', name: 'Suspendu' },
        ]}
        label="Statut"
      />
      <BooleanInput label="Donner les droits d'administration" source="isAdmin" />
    </SimpleForm>
  </Edit>
)

export default ProfessionalEdit
